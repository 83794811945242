<template>
	<div class="my-orders">
		<van-tabs v-model:active="active" @click="toggle" sticky color="#ffa000">
			<van-tab :title="item.title" :name="item.type" :key="item.type" v-for="(item,index) in tabData">
				<!-- <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh"> -->

				<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
					finished-text="我是有底线的" @load="onLoadList" style="padding:15px 15px 50px 15px;"
					v-if="ordersList.length != 0">
					<div v-for="item in ordersList" class="order-item" @click="goOrderDetail(item.id,item.type)">
						<div class="order-title">

							<span class="order-num">{{item.order_num}}</span>
							<span class="order-time">{{item.create_time}}</span>
							
						</div>
						<p>{{item.store_name}}</p>
						<div v-for="(info,ins) in item.products" class="goods">
							<van-card :price="info.price" :desc="info.attr" :title="info.name"
								:thumb="imageUrl + info.image" :num="info.count">
							</van-card>
						</div>
						<div class="order-btn" v-if="item.type == 1"><span></span><span class="order-btns">待付款</span>
						</div>
						<div class="order-btn" v-else-if="item.type == 2"><span></span><span
								class="order-btns">待发货</span></div>
						<div class="order-btn" v-else-if="item.type == 3"><span></span><span
								class="order-btns">待收货</span></div>
						<div class="order-btn" v-else-if="item.type == 4"><span></span><span
								class="order-btns">去评价</span></div>
						<div class="order-btn" v-else-if="item.type == 5"><span></span><span
								class="order-btns">已取消</span></div>
						<div class="order-btn" v-else-if="item.type == 6"><span></span><span
								class="order-btns">已退款</span></div>
						<div class="order-btn" v-else-if="item.type == 7"><span></span><span
								class="order-btns">再来一单</span></div>
					</div>

				</van-list>
				<van-empty :image="emptyImg" description="暂无订单" v-else />
				<!-- </van-pull-refresh> -->
			</van-tab>
		</van-tabs>
	</div>
</template>

<script>
	import {
		getOrderList,

	} from '@/api/order'
	import {
		toUrl
	} from '@/utils/tools'
	import {
		ref
	} from 'vue';
	import {
		useRoute
	} from "vue-router";
	export default {
		setup() {
			const router = useRoute();
			const active = ref(router.query.type);
			return {
				active
			};
		},
		computed: {

		},

		data() {
			return {
				value: '',
				iconList: '',
				imageUrl: this.url.imageUrl,
				emptyImg: require('@/assets/zwdd.png'),
				tabData: [{
						title: '全部订单',
						type: '0'
					},
					{
						title: '待付款',
						type: '1'
					},
					{
						title: '待发货',
						type: '2'
					},
					{
						title: '待收货',
						type: '3'
					},
					{
						title: '待评价',
						type: '4'
					}
				],
				nullTip: '空空如也~',
				limit: 10, // 每页条数
				page: 1, // 页码
				ordersList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				orderStatus: false
			}
		},
		beforeRouteEnter(to, from, next) {

			if (from.path == '/mine') {
				to.meta.keepAlive = false
			} else {
				to.meta.keepAlive = true
			}
			next()
		},

		mounted() {
			this.toggle(this.active)
			// this.getOrderList()
		},
		methods: {
			goOrderDetail(id, type) {
				this.$router.push({
					path: '/orderDetail',
					query: {
						id: id,
						type: type
					}
				})
			},
			toggle(name) {

				this.active = name
				this.page = 1
				this.limit = 10
				this.upFinished = false
				this.ordersList = []
				this.getOrderList()
			},
			getOrderList() {
				var that = this
				that.orderStatus = false
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.type = this.active
				params.page = this.page
				params.limit = this.limit
				getOrderList(params).then((res) => {
					console.log(res)
					if (res.data.data.length > 0) {
						const rows = res.data.data
						// console.log(rows.length)
						if (rows.length === 0) {
							// 加载结束
							this.upFinished = true
							return
						}
						if (rows.length < this.limit) {
							// 最后一页不足10条的情况
							this.upFinished = true
						}
						// 处理数据
						if (this.page === 1) {
							this.ordersList = rows
						} else {
							this.ordersList = this.ordersList.concat(rows)
						}
					} else {

						setTimeout(function() {
							that.orderStatus = true
						}, 1000)
					}
					this.$toast.clear()
				})
			},
			onDownRefresh() {

			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.getOrderList()
			},
		}
	}
</script>

<style lang="less" scoped>
	.my-orders {
		background-color: #F6F6F6;

		.order-item {
			display: flex;
			flex-direction: column;
			background-color: #FFFFFF;
			border-radius: 5px;
			padding: 15px;
			justify-content: space-between;
			margin-bottom: 15px;
			position: relative;

			.order-title {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				font-size: 12px;
				color: #666666;
			}

			.order-btn {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #ffa000;
				font-size: 14px;
				margin: 8px 0;

				.order-btns {
					max-width: 60px;
					border-radius: 5px;
					padding: 3px;
					border: solid 1px #ffa000;
				}
			}

			.order-image {
				display: flex;
				align-items: center;
				padding: 10px 0;
				justify-content: space-between;
				border-bottom: solid 1px #f2efef;

				.goods-image {
					width: 80px;
					height: 80px;
					margin-right: 10px;
					border-radius: 5px;
				}

				span {
					font-size: 12px;
					color: #999999;
				}
			}
		}
	}
</style>
