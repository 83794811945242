import request from '@/utils/request'

// 购物车提交
export function orderSubmit(data) {
    return request({
        url: '/order/submit',
        method: 'post',
        params:data,
		isLogin: true
    })
}

/**
 * lst
 * @param params
 * @returns {AxiosPromise}
 */
// 订单列表
export function orderLst(params) {
    return request({
        url: '/order/lst',
        method: 'get',
        params
    })
}

// 订单验证
export function confirm(data) {
    return request({
        url: '/order/confirm',
        method: 'post',
        data
    })
}

// 订单信息
export function orderInfo(params)
{
    return request({
        url: '/order/info',
        method: 'get',
        params
    })
}
// 订单验证
export function orderInfoDetail(params)
{
    return request({
        url: '/order/confirm',
        method: 'post',
        params,
		isLogin: true,
    })
}
// 订单列表
export function getOrderList(params)
{
    return request({
        url: '/order/lst',
        method: 'get',
        params,
		isLogin: true,
    })
}
//立即购买订单提交
export function buySubmitOrder(params)
{
    return request({
        url: '/order/buySubmit',
        method: 'post',
        params,
		isLogin: true,
    })
}
//订单提交
export function submitOrder(params)
{
    return request({
        url: '/order/submit',
        method: 'post',
        params,
		isLogin: true,
    })
}
//获取tn号
export function getTN(params)
{
    return request({
        url: '/order/pay',
        method: 'POST',
        params,
		isLogin: true,
    })
}
// 订单详情
export function getOrderDetail(params)
{
    return request({
        url: '/order/info',
        method: 'get',
        params,
		isLogin: true,
    })
}
// 立即购买订单详情
export function getBuyOrderDetail(params)
{
    return request({
        url: '/order/buyConfirm',
        method: 'post',
        params,
		isLogin: true,
    })
}

// 获取优惠券列表
export function getUserCoupon(params)
{
    return request({
        url: '/userCoupon/useList',
        method: 'get',
        params,
		isLogin: true,
    })
}

// 零元
export function orderRecetive(params)
{
    return request({
        url: '/order/receive',
        method: 'POST',
        params,
		isLogin: true,
    })
}

// 取消订单
export function orderCancel(params)
{
    return request({
        url: '/order/cancel',
        method: 'POST',
        params,
		isLogin: true,
    })
}

// 确认收货
export function orderCon(params)
{
    return request({
        url: '/order/confirmReceipt',
        method: 'POST',
        params,
		isLogin: true,
    })
}

// 订单评价
export function commentAdd(params)
{
    return request({
        url: '/comment/add',
        method: 'POST',
        params,
		isLogin: true,
    })
}

// 图片上传
export function uploadImage(data)
{
    return request({
        url: '/upload/image/one',
        method: 'POST',
        data:data,
		isLogin: true,
    })
}
